import * as React from "react"
import { Link } from "gatsby"

const PricelistHero = () => {
  return (
    <div className={"pricelist-hero"}>
      <h1>Cennik</h1>
      <div data-g="grid">
        <div data-g="4 12@sm 6@md">
          <p>
            Jesteśmy największą korporacją taxi na Prawobrzeżu. Działamy na
            terenie całego Szczecina jak i również poza jego granicami.
            Dysponujemy pojazdami zarówno 5-cio jak i 7-mio osobowymi a także
            kombi oraz bagażówkami.
          </p>
        </div>
        <div data-g="4 12@sm 6@md">
          <p>
            Na wyposażeniu naszych kierowców znajdują się foteliki dziecięce
            pozwalające zachować bezpieczeństwo naszych najmłodszych pasażerów.
            Pomożemy w awaryjnym odpalaniu samochodów a także w przypadku
            holowania.
          </p>
        </div>
      </div>
      <div className="pricelist-hero__buttons">
        <Link className={"button button--primary"} to={"/dla-firm"}>
          Poznaj ofertę dla firm
        </Link>
        <Link className={"button button--secondary"} to={"#wycen"}>
          Oblicz cenę przejazdu
        </Link>
      </div>
    </div>
  )
}

export default PricelistHero

import * as React from "react"
import { useEffect } from "react"

const list = {
  Ogólne: [
    {
      title: "Opłata początkowa",
      price: "9.99 zł",
    },
    {
      title: "Taryfa 1",
      title_more: "<span>/</span> 6:00 - 22:00",
      price: "3 zł",
      price_more: "<span>/</span> km",
    },
    {
      title: "Taryfa 2",
      title_more: "<span>/</span> 22:00 - 6:00",
      price: "4,50 zł",
      price_more: "<span>/</span> km",
    },
  ],
  "Dodatkowe usługi": [
    {
      title: "Zakupy",
      price: "30 zł (w rejonie)",
      price_more: "/ 5 zł wniesienie",
    },
    {
      title: "Zakupy",
      title_more: "Dąbie - czarna łąka",
      price: "45 zł",
      price_more: "/ 60 zł w nocy",
    },
    {
      title: "Odprowadzenie samochodu",
      price: "Taryfa x 3",
    },
    {
      title: "Holowanie samochodu",
      price: "Taryfa x 4",
    },
    {
      title: "Uruchomienie samochodu",
      price: "30 zł",
    },
  ],
  "Transfery na lotnisko": [
    {
      title: "Berlin",
      title_more: "Brandenburg",
      price: "550 zł",
    },
    {
      title: "Goleniów",
      price: "180 zł",
      price_more: "/ 180 zł w nocy",
    },
  ],
  "Transfery poza Szczecin": [
    {
      title: "Świnoujście",
      price: "300 zł",
      price_more: "/ 350 zł w nocy",
    },
    {
      title: "Biała wstążka",
      title_more: "-",
      title2: "Szczecin",
      title2_more: "Centrum",
      price: "120 zł",
      price_more: "/ 160 zł w nocy",
    },
    {
      title: "Kołbacz",
      title_more: "-",
      title2: "Szczecin",
      title2_more: "Centrum",
      price: "130 zł",
      price_more: "/ 170 zł w nocy",
    },
    {
      title: "Niedźwiedź",
      title_more: "-",
      title2: "Szczecin",
      title2_more: "Centrum",
      price: "110 zł",
      price_more: "/ 140 zł w nocy",
    },
    {
      title: "Miedwie",
      title_more: "-",
      title2: "Szczecin",
      title2_more: "os. Słoneczene",
      price: "110 zł",
      price_more: "/ 150 zł w nocy",
    },
    {
      title: "Stargard",
      title_more: "-",
      title2: "Szczecin",
      title2_more: "os. Słoneczene",
      price: "120 zł",
      price_more: "/ 160 zł w nocy",
    },
    {
      title: "Pyrzyce",
      title_more: "-",
      title2: "Szczecin",
      title2_more: "os. Słoneczene",
      price: "180 zł",
      price_more: "/ 220 zł w nocy",
    },
    {
      title: "Kołbacz",
      title_more: "-",
      title2: "Szczecin",
      title2_more: "os. Słoneczene",
      price: "80 zł",
      price_more: "/ 120 zł w nocy",
    },
    {
      title: "Kobylanka",
      title_more: "-",
      title2: "Szczecin",
      title2_more: "os. Słoneczene",
      price: "80 zł",
      price_more: "/ 110 zł w nocy",
    },
    {
      title: "Tanowo",
      title_more: "-",
      title2: "Szczecin",
      title2_more: "os. Słoneczene",
      price: "120 zł",
      price_more: "/ 140 zł w nocy",
    },
    {
      title: "Police",
      title_more: "-",
      title2: "Szczecin",
      title2_more: "os. Słoneczene",
      price: "130 zł",
      price_more: "/ 160 zł w nocy",
    },
    {
      title: "Gryfino",
      title_more: "-",
      title2: "Szczecin",
      title2_more: "os. Słoneczene",
      price: "120 zł",
      price_more: "/ 150 zł w nocy",
    },
    {
      title: "Kołbaskowo",
      title_more: "-",
      title2: "Szczecin",
      title2_more: "os. Słoneczene",
      price: "120 zł",
      price_more: "/ 150 zł w nocy",
    },
    {
      title: "Binowo",
      title_more: "-",
      title2: "Szczecin",
      title2_more: "os. Słoneczene",
      price: "80 zł",
      price_more: "/ 110 zł w nocy",
    },
    {
      title: "Reptowo",
      title_more: "-",
      title2: "Szczecin",
      title2_more: "os. Słoneczene",
      price: "90 zł",
      price_more: "/ 120 zł w nocy",
    },
    {
      title: "Jęczydół",
      title_more: "-",
      title2: "Szczecin",
      title2_more: "os. Słoneczene",
      price: "100 zł",
      price_more: "/ 140 zł w nocy",
    },
    {
      title: "Rurzyca",
      title_more: "-",
      title2: "Szczecin",
      title2_more: "os. Słoneczene",
      price: "110 zł",
      price_more: "/ 140 zł w nocy",
    },
    {
      title: "Goleniów centrum",
      title_more: "-",
      title2: "Szczecin",
      title2_more: "os. Słoneczene",
      price: "140 zł",
      price_more: "/ 180 zł w nocy",
    },
    {
      title: "Załom",
      title_more: "-",
      title2: "Lubczyna",
      price: "80 zł",
      price_more: "/ 110 zł w nocy",
    },
    {
      title: "Płonia",
      title_more: "-",
      title2: "Kołbacz",
      price: "60 zł",
      price_more: "/ 80 zł w nocy",
    },
    {
      title: "Hotel Panorama",
      title_more: "-",
      title2: "Glinna",
      price: "80 zł",
      price_more: "/ 100 zł w nocy",
    },
    {
      title: "Zdunowo",
      title_more: "-",
      title2: "Gryfino",
      price: "140 zł",
      price_more: "/ 170 zł w nocy",
    },
    {
      title: "Zdunowo",
      title_more: "-",
      title2: "Reptowo",
      price: "50 zł",
      price_more: "/ 80 zł w nocy",
    },
  ],
}

const Pricelist = () => {
  useEffect(() => {
    console.log(list)
  })
  return (
    <div className={"pricelist"}>
      {Object.keys(list).map(key => (
        <div key={key}>
          <div className="pricelist__heading">{key}</div>
          <div>
            {list[key].map((item, key2) => (
              <div className="pricelist__item" key={key2}>
                <div className="pricelist__item__left">
                  <div className="pricelist__item__value">{item.title}</div>
                  {item.title_more && (
                    <div
                      className="pricelist__item__subvalue"
                      dangerouslySetInnerHTML={{ __html: item.title_more }}
                    />
                  )}
                  {item.title2 && (
                    <div
                      className="pricelist__item__value"
                      dangerouslySetInnerHTML={{ __html: item.title2 }}
                    />
                  )}
                  {item.title2_more && (
                    <div
                      className="pricelist__item__subvalue"
                      dangerouslySetInnerHTML={{ __html: item.title2_more }}
                    />
                  )}
                </div>
                <div className="pricelist__item__right">
                  <div className="pricelist__item__background">
                    <div className="pricelist__item__value">{item.price}</div>
                    {item.price_more && (
                      <div
                        className="pricelist__item__value"
                        dangerouslySetInnerHTML={{ __html: item.price_more }}
                      />
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

export default Pricelist

import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Estimate from "../components/Common/estimate"
import Mobile from "../components/Common/mobile"
import Features from "../components/Common/features"
import PriceListHero from "../components/Pricelist/pricelist-hero"
import Pricelist from "../components/Pricelist/pricelist"
import Join from "../components/Common/join"

const PriceListPage = () => (
  <Layout>
    <Seo
      title={"Cennik"}
      description={
        "Poznaj nasze ceny i przekonaj się, że warto z nami jeździć. Oferujemy specjalne oferty taxi na specjalne okazje. Zadzwoń!"
      }
    />
    <div data-g="container">
      <PriceListHero />
      <Pricelist />
      <div id="wycen"></div>
    </div>

    <Estimate />

    <div data-g="container">
      <hr className="separator" style={{ marginBottom: "4rem" }}></hr>
      <Features />

      <Mobile />
    </div>
    <Join />
  </Layout>
)

export default PriceListPage
